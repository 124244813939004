<template>
  <v-card rounded flat class="my-10" width="100%">
    <div
      class="d-flex justify-center fill-height align-center"
      :style="{ minHeight: '7rem' }"
      v-if="loading"
    >
      <v-progress-circular
        color="primary"
        width="3"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text v-else>
      <div
        class="d-flex justify-space-around align-center"
        v-if="locationUsersCount !== 0"
      >
        <div class="text-center align-self-stretch">
          <h1 class="subtitle-1 font-weight-bold mb-3">Total Users</h1>
          <h1 class="font-weight-bold black--text display-1">
            {{ locationUsersCount }}
          </h1>
        </div>
        <template v-for="(location, index) in items">
          <div
            class="text-center align-self-stretch"
            :key="index"
            v-if="location.name !== 'Others'"
          >
            <h1 class="subtitle-1 mb-3">
              {{ location.name }}
            </h1>
            <h1 class="display-1 font-weight-bold black--text">
              {{ location.user_count }}
            </h1>
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "@/services/repositories/repositoryFactory";
const locationRepository = RepositoryFactory.get("location");

export default {
  data: () => ({
    loading: false,
    items: []
  }),
  computed: {
    locationUsersCount() {
      if (this.items.length === 0) return 0;
      return this.items
        .map(location => location.user_count)
        .reduce((flat, next) => flat + next, 0);
    }
  },
  methods: {
    async getLocationUsers() {
      this.loading = true;

      await locationRepository
        .getUsers()
        .then(response => {
          let {
            data: { responseData: items }
          } = response;

          this.items = items;
        })
        .catch(() => {
          this.items = [];
        })
        .finally(() => {
          this.loading = false;
        });
    }
  },
  mounted() {
    this.getLocationUsers();
  }
};
</script>
